import React, { useEffect, useState } from 'react'
import './App.css';
import SplashScreen from './pages/splashScreen';
import ConnectingScreen from './pages/connectingScreen';
import PaymentMethodScreen from './pages/paymentMethodScreen';
import ChargingSessionScreen from './pages/chargingSessionScreen';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OtpScreen from './pages/otpPage';
import AskOtpPage from './pages/askOtpPage';
import OccupiedPage from './pages/occupiedPage';
import { useTranslation } from 'react-i18next';


function App() {

  const [chargerPower, setChargerPower] = useState<string>('');
  const [chargerRate, setChargerRate] = useState<string>('');
  const [idleRate, setIdleRate] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(() => {
    const storedLanguage = sessionStorage.getItem('language');
    return storedLanguage ? storedLanguage : 'fi';
  });

  const [chargerID, setChargerID] = useState<string>();
  const [connectorIDFromUrl, setConnectorIDFromUrl] = useState<string>()

  const [connectorIDFromChargePointEndpoint, setConnectorIDFromChargePointEndpoint] = useState<string>()
  const [chargerIdFromChargePointEndpoint, setChargerIdFromChargePointEndpoint] = useState<string>();
  const [connectorIdForChargingSessionScreen, setConnectorIdForChargignSessionScreen] = useState<string>();
  const [stripe_pk, setStripe_pk] = useState<string>('');

  const [transactionId, setTransactionId] = useState<string>();
  const [otp, setOtp] = useState<string>();
  
  const [t, i18n] = useTranslation("global");

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  }

  useEffect(() => {
    sessionStorage.setItem('language', language);
  }, [language]);

  useEffect(() => {
    sessionStorage.getItem('language') && setLanguage(sessionStorage.getItem('language') as string);
  }, [])

  const changeLanguage = (e: any) => {
    setLanguage(e);
  }

  const CACHE_EXPIRY_MS = 5 * 60 * 60 * 1000; // five hours of expire time (5 * 60 * 60 * 1000)


  useEffect(() => {
    const checkCacheExpiry = async () => {
      
      let lastCacheClear  = localStorage.getItem('lastCacheClear');

      if(lastCacheClear == null){
        localStorage.setItem('lastCacheClear', Date.now().toString());
      }

      // If no timestamp exists or five hours have passed, clear the cache
      if (!lastCacheClear || (Date.now() - parseInt(lastCacheClear, 10) > CACHE_EXPIRY_MS)) {
        localStorage.clear();
        localStorage.setItem('lastCacheClear', Date.now().toString());

        // Clear your specific cache
        // if ('caches' in window) {
        //   const cacheNames = await caches.keys();
        //   for (const cacheName of cacheNames) {
        //     console.log("cache names --- ", cacheName)
        //     await caches.delete(cacheName);
        //   }
          
        //   
        //   console.log('Cache cleared after 5 hours');
        // }
      }
    };

    // Run the check when the component mounts
    checkCacheExpiry();
  }, []);


  return (
    <div className='root-container'>
      <Router>
        <Routes>
          <Route path='/' element={<SplashScreen
          />} />
          <Route path='/:number' element={<ConnectingScreen
            language={language}
            setLanguage={changeLanguage}
            setConnectorID={setConnectorIDFromUrl}
            setChargerID={setChargerID}
            setChargerIdFromChargePointEndpoint={setChargerIdFromChargePointEndpoint}
            setConnectorIdForChargignSessionScreen={setConnectorIdForChargignSessionScreen}
            setTransactionId={setTransactionId}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            setIdleRate={setIdleRate}
            setConnectorIDFromChargePointEndpoint={setConnectorIDFromChargePointEndpoint}
            idleRate={idleRate}
            handleChangeLanguage={handleChangeLanguage}
            transactionId={transactionId}
            setStripePk={setStripe_pk}
          />} />
          <Route path='/PaymentMethodScreen' element={<PaymentMethodScreen
            language={language}
            setLanguage={changeLanguage}
            chargerID={chargerID}
            connectorID={connectorIDFromUrl}
            setOtp={setOtp}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            handleChangeLanguage={handleChangeLanguage}
            idleRate={idleRate}
            setTransactionId={setTransactionId}
            connectorIDFromChargePointEndpoint={connectorIDFromChargePointEndpoint}
            chargerIdFromChargePointEndpoint={chargerIdFromChargePointEndpoint}
            stripe_pk={stripe_pk}
          />} />
          <Route path='/ChargingSessionScreen' element={<ChargingSessionScreen
          transactionId={transactionId}
            language={language}
            setLanguage={changeLanguage}
            chargerID={chargerID}
            connectorID={connectorIDFromUrl}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            idleRate={idleRate}
            otp={otp}
            handleChangeLanguage={handleChangeLanguage}
            connectorIDFromChargePointEndpoint={connectorIDFromChargePointEndpoint}
            chargerIdFromChargePointEndpoint={chargerIdFromChargePointEndpoint}
            connectorIdForChargingSessionScreen={connectorIdForChargingSessionScreen}
          />} />
          <Route path='/OtpScreen' element={<OtpScreen
          language={language}
            otp={otp}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            setOtp={setOtp}
            idleRate={idleRate}
            handleChangeLanguage={handleChangeLanguage}
            connectorID={connectorIDFromUrl}
            connectorIDFromChargePointEndpoint={connectorIDFromChargePointEndpoint}
           />}/>
           <Route path='/AskOtpPage' element={<AskOtpPage
           language={language}
            transactionId={transactionId}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            idleRate={idleRate}
            setOtp={setOtp}
            handleChangeLanguage={handleChangeLanguage}
            connectorID={connectorIDFromUrl}
            chargerID={chargerID}
            connectorIDFromChargePointEndpoint={connectorIDFromChargePointEndpoint}
            />}/>
            <Route path='/OccupiedPage' element={<OccupiedPage
            language={language}
            transactionId={transactionId}
            chargerPower={chargerPower}
            setChargerPower={setChargerPower}
            chargerRate={chargerRate}
            setChargerRate={setChargerRate}
            idleRate={idleRate}
            setOtp={setOtp}
            handleChangeLanguage={handleChangeLanguage}
            connectorID={connectorIDFromUrl}
            chargerID={chargerID}
            connectorIDFromChargePointEndpoint={connectorIDFromChargePointEndpoint}
            />} />
        </Routes>
      </Router>
    </div>

  );
}

export default App;
