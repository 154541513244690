import axios from 'axios';

const baseURL = 'https://plugkaro-csms.fly.dev/api/v1/';

export const getApi = async () => {
	const apiKey = process.env.API_KEY;

	const api = axios.create({
		baseURL,
		headers: {
			'Content-Type': 'application/json',
			'X-API-Key': 'key1',
		},
	});

	return api;
};