import axios from "axios";
import { getApi } from "./axios";

export async function getChargerDetails(chargerID: string | null ){
    const api = await getApi();
    const response = await api.get(`chargepoint/${chargerID}`);
    return response.data;
    // const endpoint = `chargepoint/${chargerID}`;

    // try {
    //     const response = await api.get(endpoint);
    //     return response.data;
    // } catch (error: any) {
    //     throw error.response;
    // }
}

export async function startChargerConnection(chargerID: string, connectorID: string) {

    const api = await getApi();
    const response = await api.get(`chargepoint/${chargerID}/${connectorID}`);

    return response.data;
//     const endpoint = `chargepoint/${chargerID}/${connectorID}`;

//     try {
//         const response = await api.get(endpoint);
//         if (response.status == 200) {
//             return (response.data)
//         } else {
//             return ('error')
//         }
//     } catch (error: any) {
//         console.error("Error:", error.response);
//         throw error.response;
//     }
}

export async function authorizePayment(chargerID: string, connectorID: string, requestBody: any) {
    const api = await getApi();
    const response = await api.post(`payment/authorize_payment?charge_point_id=${chargerID}&connector_id=${connectorID}`, requestBody);

    return response.data;
    // const endpoint = `payment/authorize_payment?charge_point_id=${chargerID}&connector_id=${connectorID}`;
    // const payload = requestBody;

    // try {
    //     const response = await api.post(endpoint, payload);
    //      return response.data;
    // } catch (error: any) {
    //     console.error("Error:", error.response);
    //     throw error.response;
    // }
}

export async function startChargingSession(transactionId: string, startTime: string) {
    const api = await getApi();
    const response = await api.post(`chargepoint/start_transaction`, {
        "transaction_id": transactionId,
        "start_time": startTime
     });

    return response.data;
    
    // const endpoint = `chargepoint/start_transaction`;
    // const payload = {
    //     "transaction_id": transactionId,
    //     "start_time": startTime
    //  };

    // try {
    //     const response = await api.post(endpoint, payload);
    //     return response.data;
    // } catch (error: any) {
    //     throw error.response;
    // }}
}

export async function stopChargingSession(transaction_id: any) {
    const api = await getApi();
    const response = await api.post(`chargepoint/stop_transaction?transaction_id=${transaction_id}`);
    return response.data;
    

    // const endpoint = `chargepoint/stop_transaction?transaction_id=${transaction_id}`;
    // const payload = { };

    // try {
    //     const response = await api.post(endpoint, payload);
    //     return response.data;
    // } catch (error: any) {
    //     throw error.response;
    // }
}

export async function chargingSessionStatus(transactionId: string) {

    const api = await getApi();
    const response = await api.post(`chargepoint/meter_values?transaction_id=${(transactionId).toString()}`);
    return response.data;
    // const endpoint = `chargepoint/meter_values?transaction_id=${transactionId}`;
    // const payload = { };

    // try {
    //     const response = await api.post(endpoint, payload);
    //     return response.data;
    // } catch (error: any) {
    //     throw error.response;
    // }
}

export async function getChargingSummary(transactionId: string | null){

    const api = await getApi();
    
    if(transactionId !== null){
        const response = await api.get(`payment/get_payment_summary?transaction_id=${transactionId}`);
        return response.data;
        // const endpoint = `payment/get_payment_summary?transaction_id=${transactionId}`;
        // try {
        //     const response = await api.get(endpoint);
        //     return response.data;
        // } catch (error: any) {
        //     throw error.response;
        // }
    }
}

export async function validateOtp(transactionId: any, otp: string) {
    const api = await getApi();
    const response = await api.post(`/authentication/validate_otp?transaction_id=${transactionId}&otp=${otp}`);
    return response.data;

    // const endpoint = `/authentication/validate_otp?transaction_id=${transactionId}&otp=${otp}`;
    // const payload = { };

    // try {
    //     const response = await api.post(endpoint, payload);
    //     return response.data;
    // } catch (error: any) {
    //     throw error.response;
    // }
}

export async function sendEmailInvoice(requestOption: any){

    const api = await getApi();
    const response = await api.post(`/invoices/payment_receipt`, requestOption);
    return response.data;

    // const endpoint = `/invoices/payment_receipt`;
    // const payload = requestOption;

    // try {
    //     const response = await api.post(endpoint, payload);
    //     return response;
    // } catch (error: any) {
    //     throw error.response;
    // }


}
