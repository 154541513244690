import React, { useEffect, useState } from 'react'
import './style.css'
import { stopChargingSession, chargingSessionStatus, startChargingSession, getChargingSummary, sendEmailInvoice, startChargerConnection } from '../../api/api';
import FadeLoader from "react-spinners/FadeLoader";
import Lottie from 'lottie-react';
import batteryCharging from '../../assets/batteryCharging.json';
import { useTranslation } from 'react-i18next';
import { format } from 'path';
import Timer from './Timer';
import { useSwipeable } from 'react-swipeable';

export default function ChargingSessionScreen(props: any) {
    const [t, i18n] = useTranslation('global');
    const [chargingPower, setChargingPower] = useState<number>(0.00);
    const [chargingTime, setChargingTime] = useState<string>('0:00:00');
    const [chargingCost, setChargingCost] = useState<number>(0.0000);
    const timeDisplay = document.getElementById('time') as HTMLDivElement;
    const [stopChargingButtonText, setStopChargingButtonText] = useState<string>(t("chargingSessionScreen.stopCharging"));
    const [isChargingStopped, setIsChargingStopped] = useState<boolean>(false);
    const [isChargingSuspended, setIsChargingSuspended] = useState<boolean>(false);
    const [isChargingStarted, setIsChargingStarted] = useState<boolean>(false);
    const [language, setLanguage] = useState<string>(props.language);
    const [isChargingStopButtonClicked, setIsChargingStopButtonClicked] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [transactionRef, setTransactionRef] = useState<string>('');
    const [userEmail, setUserEmail] = useState<string | null>(null);
    const [invoiceEmailState, setInvoiceEmailState] = useState<string>('');
    const [meterStartTime, setMeterStartTime] = useState<string>('');
    const [isChargingSessionStoppedByUser, setIsChargingSessionStoppedByUser] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [initialMeterValue, setInitialMeterValue] = useState<number>(0);
    const [isTimerRendered, setIsTimerRendered] = useState<boolean>(false);
    const [idleTimeCalculated, setIdleTimeCalculated] = useState<boolean>(false);
    const [idleTime, setIdleTime] = useState<string>("00:00:00");
    const [idleFee, setIdleFee] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [timeElapsed, setTimeElapsed] = useState<string>("00:00:00"); // this is the time once charging session is completed. coming from the API (chargign summary).
    const [paymentSummaryCaptured, setPaymentSummaryCaptured] = useState<boolean>(false);
    const [cableConnectionLoose, setCableConnectionLoose] = useState<boolean>(false);
    

    let pageLoadTime = new Date().toISOString();
    const pageLoadTimeMillis = new Date(pageLoadTime).getTime();


    useEffect(() => {
        sessionStorage.getItem('language') && props.handleChangeLanguage(sessionStorage.getItem('language') as string);
      }, []);

      useEffect(() => {
        sessionStorage.setItem('language', language);
      }, [language]);

      const changeReceiptRequestEmailAddress = (e: any) => {
        setInvoiceEmailState('');
      }

    useEffect(() => {
        if(isNaN(chargingPower)){
            setChargingPower((0.00));
        }
    }, [chargingPower])

    useEffect(() => {
        if(isNaN(chargingCost)){
            setChargingCost(Number(0.00));
        }
    }, [chargingCost])

    useEffect(() => {
        if(isChargingStarted == false && isChargingStopped == false && isTimerRendered == false){
            setShowSpinner(true);
        } else if (isChargingStarted == true && isChargingStopped == false && isTimerRendered == true){
            setShowSpinner(false);
        } else if(isChargingStopped == true && isTimerRendered == true){
            setShowSpinner(false);
        }
    }, [isChargingStopped, isChargingStarted, isTimerRendered])
    
    const [chargingSessionSummary, setChargingSessionSummary] = useState<{
        power_consumed: any,
        final_amount: any,
        status: any
    }>({
        power_consumed: null,
        final_amount: null,
        status: null
    });

    const [timer, setTimer] = useState<number>(0);

    useEffect(() => {

        if(chargingTime == '0:00:00'){
            setStopChargingButtonText(t("chargingSessionScreen.preparing")); 
        }else {
            setStopChargingButtonText(t("chargingSessionScreen.stopCharging"));
        }

        //setIsChargingStarted(true); // when charging session starts, timer start to run.

        }, [chargingTime])
        
    useEffect(() => {
        if(isChargingStopped){
            setIsChargingStopButtonClicked(false);
        }
    }, [isChargingStopped])

    useEffect(() => {
        setTransactionId(props.transactionId);
        window.scrollTo(0, 0);
        const newUrl = props.connectorIDFromChargePointEndpoint;
        
        const checkUrl = () => {
            const url = window.location.href;
            window.history.replaceState(null, '', newUrl);
            const lastPartOfUrl = url.substring(url.lastIndexOf('/') + 1);
            if (lastPartOfUrl !== newUrl) {
                setTimeout(checkUrl, 1000); // Check again after 1 second
            }else {
                sessionStorage.setItem('otp', props.otp);
                localStorage.setItem('otp', props.otp);
            }
        };

        checkUrl();

      }, []);

      const getPaymentSummaryWhenCableRemoved = () => {
        setTimeout(() => {
            if(isChargingStopped){
                let sessionStatus = ''; // is charging session 'completed' or 'finished'. completed = idle time calcualted and email sent, finished = just session is stoped but idel is calculating.

                const fetchData = async () => {
                    
                    let chargingSummary = await getChargingSummary(transactionId);
                    setChargingSessionSummary(chargingSummary);
                    console.log("charging summary --- ", chargingSummary); 
                    setTimeout(() => {
                        sessionStatus = chargingSummary.status;
                        let transactionRef = chargingSummary.transaction_ref;   // check this again with argon
                        setTransactionRef(transactionRef);
                        let consumed_power = (Number(chargingSummary.power_consumed))/1000;
                        let finalAmount = (Number((chargingSummary.final_amount)/100)).toFixed(2);
                        let idleAmount = (Number((chargingSummary.idle_fee)/100)).toFixed(2);
                        let totalAmoount =(Number((chargingSummary.total_amount)/100)).toFixed(2);

                        setChargingPower(Number(consumed_power.toFixed(2)));
                        setChargingCost(Number(finalAmount));

                        setIdleFee(Number(idleAmount));
                        setTotalAmount(Number(totalAmoount));
                        setIdleTime(chargingSummary.idle_time);
                        setTimeElapsed(chargingSummary.time_elapsed);
                        setPaymentSummaryCaptured(true);
                        setIdleTimeCalculated(true);
                    }, 2000);
                    localStorage.removeItem("transactionId");
                    localStorage.removeItem("sessionId");
                };

                if(sessionStatus != 'completed'){
                    console.log("call charging summary until get the idle time and"); 
                    fetchData();
                } else if (sessionStatus == 'completed'){
                    console.log("idle time completed")
                    setIdleTimeCalculated(true);
                }

                fetchData();
            }
        }, 3000)
      }

    useEffect(() => {
        setTimeout(() => {
            if(isChargingStopped){
                let sessionStatus = ''; // is charging session 'completed' or 'finished'. completed = idle time calcualted and email sent, finished = just session is stoped but idel is calculating.

                const fetchData = async () => {
                    
                    let chargingSummary = await getChargingSummary(transactionId);
                    setChargingSessionSummary(chargingSummary);
                    console.log("charging summary --- ", chargingSummary); 
                    setTimeout(() => {
                        sessionStatus = chargingSummary.status;
                        let transactionRef = chargingSummary.transaction_ref;   // check this again with argon
                        setTransactionRef(transactionRef);
                        let consumed_power = (Number(chargingSummary.power_consumed))/1000;
                        let finalAmount = (Number((chargingSummary.final_amount)/100)).toFixed(2);
                        let idleAmount = (Number((chargingSummary.idle_fee)/100)).toFixed(2);

                        setChargingPower(Number(consumed_power.toFixed(2)));
                        setChargingCost(Number(finalAmount));

                        setIdleFee(Number(idleAmount));
                        setIdleTime(chargingSummary.idle_time);
                        setTimeElapsed(chargingSummary.time_elapsed);
                        setPaymentSummaryCaptured(true);
                    }, 2000);
                    localStorage.removeItem("transactionId");
                    localStorage.removeItem("sessionId");
                };

                if(sessionStatus != 'completed'){
                    console.log("call charging summary until get the idle time and"); 
                    fetchData();
                } else if (sessionStatus == 'completed'){
                    console.log("idle time completed")
                    setIdleTimeCalculated(true);
                }

                fetchData();
            }
            checkConnectionStatusWhenCableRemoved();
        }, 3000)
        
    }, [isChargingStopped])



    const [chargerStopTimer, setChargerStopTimer] = useState<number>(0);

    const formatTime = (seconds: number) => {
        if (seconds < 0 || seconds == 0 || isNaN(seconds)) {
            setChargingTime(`0:00:00`)
            // throw new Error('Input must be a non-negative number of seconds.');
        }else {
            if(!isChargingStopped){
                setChargerStopTimer(seconds);
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = seconds % 60;
                if(minutes < 10){
                    setChargingTime(`${hours}:0${minutes}:${remainingSeconds}`);
                    if(remainingSeconds < 10){
                        setChargingTime(`${hours}:0${minutes}:0${remainingSeconds}`);
                    }
                }else if(remainingSeconds < 10){
                setChargingTime(`${hours}:${minutes}:0${remainingSeconds}`);

                }else{
                    setChargingTime(`${hours}:${minutes}:${remainingSeconds}`);
                }
            }else {
                const hours = Math.floor(chargerStopTimer / 3600);
                const minutes = Math.floor((chargerStopTimer % 3600) / 60);
                const remainingSeconds = chargerStopTimer % 60;
                if(minutes < 10){
                    setChargingTime(`${hours}:0${minutes}:${remainingSeconds}`);
                    if(remainingSeconds < 10){
                        setChargingTime(`${hours}:0${minutes}:0${remainingSeconds}`);
                    }
                }else if(remainingSeconds < 10){
                setChargingTime(`${hours}:${minutes}:0${remainingSeconds}`);

                }else{
                    setChargingTime(`${hours}:${minutes}:${remainingSeconds}`);
                }
            }
        }
        
        
    }

    // active, charging, suspended

    const startCharging = async () => {
        try {
            
        await startChargerConnection(props.chargerIdFromChargePointEndpoint, props.connectorIdForChargingSessionScreen).then(async (res: any) => {
                if(res.status == 'charging' || res.status == 'Charging' || res.status == 'suspended'){
                    getChargingSessionStatus(props.transactionId);
                } else if((res.status) == 'active' || (res.status) == 'Active'){
                    const sessionId = sessionStorage.getItem("sessionId");
                    setTransactionId(props.transactionId);
                    let res: any;
                    let startTime =  Date.now();

                    setMeterStartTime((new Date(startTime).getTime()).toString());
                    setCableConnectionLoose(false);
                    setIsChargingStarted(true);
                    try {
                        await startChargingSession(props.transactionId, startTime.toString()).then((result: any) => {
        
                            res = result;
                            //not-charging
                            if(result.status == "charging" || result.status == "Charging"){
                                setCableConnectionLoose(false);
                                setIsChargingStarted(true);
                                setTimeout(() => {
                                    // runTimer();
                                    
                                }, 3000)
                                document.cookie = `myCookie=${sessionId}; expires=Wed, 31 Dec 2025 23:59:59 GMT; path=/ChargingSessionScreen`
                            } else if(result.status == "Available"){
                                setCableConnectionLoose(false);
                                setTimeout(() => {
                                    startCharging();
                                }, 30000);
                            } else if(result.status == "not_charging"){
                                let currentimeMillis = new Date(pageLoadTime).getTime();
                                if((currentimeMillis - pageLoadTimeMillis) > 10000){
                                    setCableConnectionLoose(true);  
                                }
                                // setTimeout(() => {
                                //     startCharging();
                                // }, 10000);
                                
                            }
        
                            setTimeout(() => {
                                if(!isChargingSessionStoppedByUser){
                                    if(!isChargingStopped){
                                        getChargingSessionStatus(props.transactionId!);
                                    }
                                }   
                            }, 5000);
                        })
                    } catch (error) {
                        console.error(error);
                    }
                }
            })
            
        } catch (error: any) {
            console.log('error :- ', error);
        }
    
    }

    const stopChargingSessionButtonClick = async () => {
        console.log("summary call")
        setIsChargingStopButtonClicked(true);
        setTimeout(() => {

        }, 3000)
        try {
            const response = await stopChargingSession(props.transactionId);
            if(response){
                console.log("summary call 2")
                if (response.status == 'success') {
                    console.log("summary call 3")
                    setIsChargingSessionStoppedByUser(true);
                        setChargingCost(Number(response.final_payment)/100);
                        setChargingPower((Number(response.power_consumed))/1000);
                        // Set the timer later ...
                        setIsChargingStopped(true);
                        setStopChargingButtonText(t("chargingSessionScreen.chargingStoped"));  
                        checkConnectionStatusWhenCableRemoved();
                } else {
                    console.log("summary call 4")
                    setTimeout(() => {
                        stopChargingSessionButtonClick();
                    }, 30000);
                }
            }
            
        } catch (error: any) {
            console.error(error);
            setIsChargingStopButtonClicked(false);
        }
    }

    const checkConnectionStatusWhenCableRemoved = async () => { 
        /*
        when chargign sessin is stopped from the web app by user, This method will repeatedly check the connection status.
        Once the status become 'Available' then it will call the getPaymentSummaryWhenCableRemoved() method.
        */

        const response = await startChargerConnection(props.chargerIdFromChargePointEndpoint, props.connectorIdForChargingSessionScreen).then(async (res: any) => {
            if(res.status == 'Available' || res.status == 'available'){
                getPaymentSummaryWhenCableRemoved();
            } else {
                setTimeout(() => {
                    checkConnectionStatusWhenCableRemoved();
                }, 2000)
            }
        });
    }

    const runTimer = () => {

        if(meterStartTime !== ''){
            let myTimer = new Date(meterStartTime).toISOString();

            let current_time = new Date().toISOString();
            let startTime = new Date(myTimer).getTime();
            let currentTime = new Date(current_time).getTime();
            let elapsedTimeInSeconds = Math.floor((currentTime - startTime) / 1000);
            formatTime(Math.floor(initialMeterValue)); // reduce three hours from UTC time. Only for pilot project
        }
 
    }

    const startTimer = () => {
        const interval = setInterval(() => {
            if(isChargingStopped == true){
                return;
            }
            runTimer();
        }, 1000);
        return () => clearInterval(interval);
    }



    const [finalMeterValue, setFinalMeterValue] = useState<number>(0);

    useEffect(() => {
        // startTimer();
    }, [meterStartTime]);

    // useEffect(() => {
    //     runTimer();
    // }, [initialMeterValue])

    // useEffect(() => {
    //     setChargingPower((initialMeterValue)/1000);
    // }, [finalMeterValue])

    const getChargingSessionStatus = async (transactionID: string) => {
        let response;
        if(!isChargingSessionStoppedByUser){
            if(!isChargingStopped && !isChargingStopButtonClicked){

                await chargingSessionStatus((transactionID).toString()).then(
                    (res: any) => {
                        setMeterStartTime(new Date(res.meter_start_time).toLocaleString());
                        if(res.charge_point_status == "preparing"){
                            startCharging();
                        }else{
                            setTransactionRef(res.transaction_ref);
                            response = res;
                            if(!isChargingStopButtonClicked){
                                if(res.charge_point_status == 'charging'){
                                    setCableConnectionLoose(false);
                                    setChargingCost(Number(res.amount)/100);
                                    let currentTime = Date.now();
                                    if(res.meter_start_time != null){
                                        let startTime = res.meter_start_time
                                        setInitialMeterValue(Number(currentTime - startTime));
                                    }
                                    
                                    setChargingPower(Number(res.meter_values.value)/1000);
                                    setTransactionRef(res.transaction_ref);
                                    setShowSpinner(false);
                                }
                            }

                            if (res.charge_point_status == 'charging') { // to Argon, once charging session is stoped, this state need to be change to 'finished' in API response. But it is not happening now. Argon need to fix this.                    
                                setIsChargingSuspended(false);
                                setCableConnectionLoose(false);
                                setTransactionRef(res.transaction_ref);
                                setStopChargingButtonText(t("chargingSessionScreen.stopCharging"));
                                setTimeout(() => {
                                    getChargingSessionStatus(transactionID);
                                    setShowSpinner(false);
                                }, 10000)
                            } else if (res.charge_point_status == 'suspended') {
                                setCableConnectionLoose(false);
                                setTransactionRef(res.transaction_ref);
                                setChargingCost(Number(res.amount)/100);
                                let currentTime = Date.now();
                                let startTime = res.meter_start_time
                                if(res.meter_start_time != null){
                                    setInitialMeterValue(Number(currentTime - startTime));
                                }
                                setChargingPower(Number(res.meter_values.value)/1000);
                                if(initialMeterValue > 10000){
                                    setShowSpinner(false);
                                }else {
                                    setShowSpinner(true);
                                }
                                
                                
                                // if charging time is lessthan 10 seconds, avoid the suspend state. Or else flow on the normal flow. This is doing because of we need to skip the first suspend state sent from the charger. At the starting time of the charging session.
                                
                                if( res.meter_start_time != null && Number(currentTime - res.meter_start_time) > 10000){
                                    setIsChargingSuspended(true);
                                    setShowSpinner(false);
                                }

                                setTimeout(() => {
                                    getChargingSessionStatus(transactionID);
                                    setShowSpinner(false);
                                }, 10000)
                            } else if(res.charge_point_status == 'active'){
                                setCableConnectionLoose(true);
                                setTimeout(() => {
                                    getChargingSessionStatus(transactionID);

                                }, 10000)
                                // do nothing
                                // if the 'res.charge_point_status' means, charging session is trying to start but cable connection is poor. Once the cable is connected properly, it will start the charging session automatically.  
                            } else if(res.charge_point_status == 'cancelled'){
                                window.location.reload(); 
                            }
                             else {
                                setCableConnectionLoose(false);
                                setTransactionRef(res.transaction_ref);
                                setIsChargingStopped(true);
                                // setStopChargingButtonText(t("chargingSessionScreen.chargingStoped"));
                            }
                        }
                        
                    }
                )
            }
            
        }else {
            if(transactionID){
                await chargingSessionStatus((transactionID).toString()).then(
                    (res: any) => {
                        if((res.charge_point_status) == 'finishing' || (res.charge_point_status) == 'Finishing' || (res.charge_point_status) == 'finished'){
                            setCableConnectionLoose(false);
                            setTransactionRef(res.transaction_ref);
                            setChargingCost(Number(res.amount)/100);
                            let currentTime = Date.now();
                            let startTime = res.meter_start_time
                            if(res.meter_start_time != null){
                                setInitialMeterValue(Number(currentTime - startTime));
                            }
                            setChargingPower(Number(res.meter_values.value)/1000);
                            if(initialMeterValue > 10000){
                                setShowSpinner(false);
                            }else {
                                setShowSpinner(true);
                            }
                            
                            
                            // if charging time is lessthan 10 seconds, avoid the suspend state. Or else flow on the normal flow. This is doing because of we need to skip the first suspend state sent from the charger. At the starting time of the charging session.
                            
                            if( res.meter_start_time != null && Number(currentTime - res.meter_start_time) > 10000){
                                setIsChargingSuspended(true);
                                setShowSpinner(false);
                            }

                            setTimeout(() => {
                                getChargingSessionStatus(transactionID);
                                setShowSpinner(false);
                            }, 10000)

                        } 
                        
    
                    })    
            }
                    
        }
    }

    const requestEmailInvoice = async () => {

        let requestBody = {
            "transaction_ref": transactionRef?.toString(),
            "email": userEmail
          }

          try {
            await sendEmailInvoice(requestBody).then((res: any) => {

                    res.detail == 'Transaction is not completed.' ? setInvoiceEmailState('sent') : setInvoiceEmailState('failed');

            })
          } catch (error) {
            console.log("email sent error :- ", error);
          }
        
    }

    useEffect(() => {

        const initialStartCharging = async () => {
            if (!isChargingStarted || transactionId) {
                await startChargerConnection(props.chargerIdFromChargePointEndpoint, props.connectorIdForChargingSessionScreen).then(async (res: any) => {
                    if(res.status == 'Active' || res.status == 'active'){
                        startCharging();
                    } else {
                        if(!isChargingStopButtonClicked){
                            getChargingSessionStatus(props.transactionId);
                        }
                    }
                });
                
            } 
        }

        initialStartCharging();
        
    }, [isChargingStarted, transactionId])

    // swipe button

    const [progress, setProgress] = useState(0); // Start at 10% fill
  const [isSwiped, setIsSwiped] = useState(false); // Track swipe completion

  const handleSwipeRight = () => {
    if (progress >= 80) {
      setIsSwiped(true);
      stopChargingSessionButtonClick();
    }
  };

  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      // Calculate swipe progress based on swipe distance, starting from 10%
      const swipeProgress = Math.min(100, 10 + (eventData.deltaX / window.innerWidth) * 100);
      setProgress(swipeProgress);
    },
    onSwiped: () => {
      // Reset progress if the swipe isn't complete
      if (progress < 80) {
        setProgress(0); // Reset to 10% if not fully swiped
      } else {
        setProgress(100);
        handleSwipeRight();
      }
    },
    trackMouse: true,
  });

  let x = 'idleTimeCalculated';
  let y = 'this should be true'

        return (
            <div className="flex flex-col justify-center items-center h-screen w-screen bg-iparkOrange800">
                <div className='flex flex-row-reverse w-full pr-5 below-400:mt-20 above-400:mt-5'>
                <select
                    value={props.language}
                    onChange={(e) => props.handleChangeLanguage(e.target.value)}
                    className="bg-white border border-gray-300 rounded-md text-xs focus:outline-none"
                >
                    <option value="fi">FI</option>
                    <option value="en">EN</option>
                    {/* <option value="sw">SW</option> */}
                </select>
                </div>
                <div className="flex flex-row justify-between w-full pl-5 pr-5">
                    <div className="flex flex-col bg-white py-5 my-5 font-bold rounded-tl-30 rounded-tr-30 rounded-bl-30 rounded-br-30 w-full " style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)' }}>
{/*  */}

{/*  */}
                        <div className='flex flex-row justify-between text-xs pl-10 pr-10'>
                            <div>
                                {props.chargerPower} kW
                                <br />
                                <div className='flex text-gray-400 font-light'>
                                {t("generalDetails.power")}
                                </div>
                            </div>
                            <div>
                                {props.chargerRate} €/kWh
                                <br />
                                <div className='flex text-gray-400 font-light'>
                                {t("generalDetails.unitPrice")}
                                </div>
                            </div>
                            <div>
                                {props.idleRate} €/min
                                <br />
                                <div className='flex text-gray-400 font-light'>
                                {t("generalDetails.idleFee")}
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex text-xs pl-10 pr-10 text-gray-400 font-light pt-5'>
                            {t("generalDetails.otp")} {props.otp}
                        </div> */}
                    </div>
                </div>
                <div className="flex justify-center items-center h-2/6">
                    <img src={require('../../assets/icons/Final3.png')} alt="" />
                </div>
                
                <div className='flex flex-col justify-start rounded-tl-30 rounded-tr-30 items-center pt-3 h-4/6 w-screen bg-white'>
                    <div className={isChargingStopped ? "flex py-5 pt-5 my-5 mt-5 justify-center flex-col items-center rounded-tl-30 rounded-tr-30 rounded-bl-30 rounded-br-30 bg-gray-100 w-5/6 shadow-md text-black font-bold text-md md:text-md xl:text-xl" : "flex py-5 -mb-20 pt-5 my-5 mt-5 justify-center flex-col items-center rounded-tl-30 rounded-tr-30 rounded-bl-30 rounded-br-30 bg-gray-100 w-5/6 shadow-md text-black font-bold text-md md:text-md xl:text-xl"} >
                        
                    {/* {(chargingTime == '0:00:00' ) ?  */}
                    {/* {(showSpinner == true ) ?  */}
                            <div className={`flex flex-col justify-center items-center ${showSpinner ? '' : 'hidden'}`}>
                                {/* color FF6D00 */}
                                <FadeLoader
                                color="#FF6D00"
                                loading={true}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                />
                                <span className={`text-gray-500 ${showSpinner ? '' : 'hidden'}`}>
                                {t("chargingSessionScreen.chargerFetchingDataMessage")}
                                </span>
                            </ div>

                        {
                            cableConnectionLoose && (
                                                <div className='flex justify-center text-center'>
                                                    {t("chargingSessionScreen.chargerCannotStartCheckTheCableConnection")}
                                                </div>
                            )
                        }
                        
                        
                        <div hidden={showSpinner}>
                            {
                                    isChargingSuspended == true ? 
                                        isChargingStopped == true ? 
                                            '' 
                                            : 
                                            <div className='flex justify-center items-center w-full text-xs text-gray-400 text-center p-3'>
                                            {t("chargingSessionScreen.chargignIsSuspended")}
                                            </div> 
                                    :
                                    !isChargingStopped && 
                                    
                                    <div className='flex justify-center items-center w-full text-xs text-gray-400 text-center p-3'>
                                    {t("chargingSessionScreen.consumedPowerUpdateText")}
                                    </div>
                            }
                            
                            

                            {/* idle time and idle fee below. */}
                            {
                                isChargingStopped == true ?  // true

                                paymentSummaryCaptured == true ? // 'paymentSummaryCaptured == true ?' this is the condition when we need to show the idle fee and total amount.
                                // this is when charging session is completed
                                <>
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/2 items-center justify-end text-end text-gray-400 text-xs'>
                                            {/* <img src={require('../../assets/icons/orangeThemeConsumedPower.png')} alt="" /> */}
                                            {t("chargingSessionScreen.usedPower")}
                                        </div>
                                        <div className='flex w-1/2 ml-10'>
                                            <span>{(chargingPower)?.toFixed(2)} kWh</span>
                                        </div>
                                    </div>
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/2 items-center justify-end text-end text-gray-400 text-xs'>
                                            {/* <img src={require('../../assets/icons/orangeThemeElapsedTime.png')} alt="" /> */}
                                            {t("chargingSessionScreen.chargingTime")}
                                        </div>
                                        <div className='flex w-1/2 ml-10'>
                                            <span>{(timeElapsed)}</span>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/2 items-center justify-end text-gray-400 text-end text-xs'>
                                            {/* <img src={require('../../assets/icons/orangeThemeAmountSpent.png')} alt="" /> */}
                                            {t("chargingSessionScreen.chargingCost")}
                                        </div>
                                        <div className='flex w-1/2 ml-10'>
                                            {(chargingCost)?.toFixed(2)}€
                                        </div>
                                    </div>

                                    { x == y ? // true // 'idleTimeCalculated == true ?' this is the condition when we need to show the idle fee and total amount.
                                    <>
                                        <div className='flex justify-center items-center w-full'>
                                            <div className='flex w-1/2 items-center text-center text-gray-400 justify-end text-xs'>
                                                {/* <img src={require('../../assets/icons/orangeThemeAmountSpent.png')} alt="" /> */}
                                                {t("chargingSessionScreen.idleTime")}
                                            </div>
                                            <div className='flex w-1/2 ml-10'>
                                                {(idleTime)}
                                            </div>
                                        </div>
                                        <div className='flex justify-center items-center w-full'>
                                            <div className='flex w-1/2 items-center justify-end text-center text-gray-400 text-xs'>
                                                {/* <img src={require('../../assets/icons/orangeThemeAmountSpent.png')} alt="" /> */}
                                                {t("chargingSessionScreen.idleFee")}
                                            </div>
                                            <div className='flex w-1/2 ml-10'>
                                                {(idleFee)?.toFixed(2)}€
                                            </div>
                                        </div>
                                        <div className='flex justify-center items-center w-full'>
                                            <div className='flex w-1/2 items-center justify-end text-center text-gray-400 text-xs'>
                                                {/* <img src={require('../../assets/icons/orangeThemeAmountSpent.png')} alt="" /> */}
                                                {t("chargingSessionScreen.totalFee")}
                                            </div>
                                            <div className='flex w-1/2 ml-10'>
                                                {(totalAmount)?.toFixed(2)}€
                                            </div>
                                        </div>

                                        
                                    </>
                                    :
                                    <>
                                        <div className='flex justify-center items-center w-full text-xs text-gray-400 text-center p-3'>
                                            {t("chargingSessionScreen.untilIdleTimeCalculated")}
                                        </div>
                                    </>
                                    }
                                    
                                </>
                                :
                                <FadeLoader
                                    color="#FF6D00"
                                    loading={true}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                                
                                    
                                
                                :
                                // this is when chargign session is going on
                                <> 
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/3 items-center justify-center text-center'>
                                            <img src={require('../../assets/icons/orangeThemeElapsedTime.png')} alt="" />
                                        </div>
                                        <div className='flex w-2/3 ml-10'>
                                            {initialMeterValue > 0 ? <Timer startTime={initialMeterValue}
                                            setIsTimerRendered={setIsTimerRendered}
                                            isChargingStopped={isChargingStopped}
                                            isChargingSuspended={isChargingSuspended}
                                        /> : <></>}
                                        </div>
                                    </div>
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/3 items-center justify-center text-center'>
                                            <img src={require('../../assets/icons/orangeThemeConsumedPower.png')} alt="" />
                                        </div>
                                        <div className='flex w-2/3 ml-10'>
                                            <span>{(chargingPower)?.toFixed(2)} kWh</span>
                                        </div>
                                    </div>
                                    
                                    <div className='flex justify-center items-center w-full'>
                                        <div className='flex w-1/3 items-center justify-center text-center'>
                                            <img src={require('../../assets/icons/orangeThemeAmountSpent.png')} alt="" />
                                        </div>
                                        <div className='flex w-2/3 ml-10'>
                                            {(chargingCost)?.toFixed(2)}€
                                        </div>
                                    </div>
                                </>
                            }

                            
                        
                        </div>
                    </div>

                    {

                        (isChargingSuspended ? false : (isChargingStopped ? false : (cableConnectionLoose ? false : true))) &&

                        <div className='flex h-1/3 w-full justify-center'>
                                <Lottie animationData={batteryCharging} autoplay={true}/>
                        </div>
                    }

                    {

                        (isChargingSuspended ? true : (isChargingStopped ? true : (cableConnectionLoose ? true : false))) &&

                        <div className='flex h-1/3 w-full justify-center'>
                                <Lottie animationData={batteryCharging} autoplay={false}/>
                        </div>
                        }


                            
                    
                    {
                        isChargingSuspended == false ?
                                (showSpinner == false) ? 
                                    <div className="flex justify-center flex-col items-center w-5/6 pb-5">
                                    
                                    {isChargingStopButtonClicked ?
                                    
                                        <FadeLoader
                                            color="#FF6D00"
                                            loading={true}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        /> : 
                                        (
                                            isChargingStopped == false ?
                                            <>
                                            {/* <button className={(isChargingStopped ? 
                                            'flex bg-iparkOrange200 w-full text-center justify-center rounded-md text-black text-md py-3' 
                                            : 
                                                'flex bg-red-600 w-full text-center justify-center rounded-md text-white text-md py-3'
                                            )} onClick={stopChargingSessionButtonClick}>
                                                {stopChargingButtonText}
                                            </button>  */}

                                            {/* stop charging button start */}
                                            <div
                                                {...handlers}
                                                className="relative w-full max-w-xs bg-gray-200 rounded-full overflow-hidden flex items-center"
                                                style={{ height: '50px' }}
                                                >
                                                {/* Fixed 10% initial segment without icon */}
                                                <div className="w-[15%] h-full bg-red-500 rounded-l-full rounded-r-full">
                                                    <div className="flex items-center justify-center h-full">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6 text-white"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth={2}
                                                        d="M9 5l7 7-7 7"
                                                        />
                                                    </svg>
                                                    </div>
                                                </div>

                                                {/* Red fill overlay tracking the user's swipe, starting from 10% */}
                                                <div
                                                    className="absolute top-0 left-0 h-full bg-red-500  rounded-r-full"
                                                    style={{
                                                    width: `${progress}%`,
                                                    marginLeft: '0%', // Offset to start from the end of the fixed 10% section
                                                    }}
                                                />

                                                {/* Label */}
                                                {/* <div className="relative z-10 flex-1 flex items-center justify-center h-full text-gray-700 font-bold">
                                                    {stopChargingButtonText}
                                                </div> */}
                                                <button className="relative z-10 flex-1 flex items-center justify-center h-full text-gray-700 font-bold -ml-4">
                                                {stopChargingButtonText}
                                            </button> 
                                            </div>
                                            {/* stop charging button stop */}
                                            <br />
                                            <span className='flex text-center text-gray-400 text-xs'>{t("chargingSessionScreen.stopChargingSessionFromCarApp")}</span>
                                            <br/>

                                            
                                            </>
                                            : 
                                            <>
                                            </>
                                        )
                            
                                    }
                                    <>
                                    {
                                                invoiceEmailState == 'sent' ?
                                                <span className='flex pt-5 text-center '>{t("chargingSessionScreen.receiptRequested")}</span>
                                                :
                                                null
                                            }

                                                <>
                                                    <input type="text" className='border border-gray-300 bg-gray-100 w-full rounded-md px-4 py-2 focus:outline-none focus:border-green-500 text-center text-black' placeholder={t("chargingSessionScreen.enterYourEmail")} onBlur={(e: any) => setUserEmail(e.target.value)} onChange={(e: any) => changeReceiptRequestEmailAddress(e.target.value)}/>
                                                    {
                                                        invoiceEmailState == 'sent' ? 
                                                        <button className={'flex bg-green-500 w-full text-center justify-center py-3 mt-5 rounded-md text-black text-md'} onClick={requestEmailInvoice}>{t("chargingSessionScreen.emailRequested")}</button>
                                                        :
                                                        <button className={'flex bg-iparkOrange800 w-full text-center justify-center py-3 mt-5 rounded-md text-black text-md'} onClick={requestEmailInvoice}>{t("chargingSessionScreen.requestEmailReceipt")}</button>
                                                    }
                                                </></>
                                    </div>
                                :
                                <></>
                            :
                            <>
                                {
                                    (showSpinner == false) ? 
                                    <div className="flex justify-center flex-col items-center w-5/6 pb-5">
                                    
                                    {isChargingStopButtonClicked ?
                                    
                                        <FadeLoader
                                            color="#FF6D00"
                                            loading={true}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        /> : 
                                        (
                                            isChargingStopped == false ?
                                            <>
                                            <button className={(isChargingStopped ? 
                                            'flex bg-gray-200 w-full text-center justify-center rounded-md text-black text-md py-3' 
                                            : 
                                                'flex bg-gray-200 w-full text-center justify-center rounded-md text-white text-md py-3'
                                            )} onClick={stopChargingSessionButtonClick}>
                                                Suspended
                                            </button> 
                                            <br/>

                                            
                                            </>
                                            : 
                                            <>
                                            </>
                                        )
                            
                                    }
                                    <>
                                    {
                                                invoiceEmailState == 'sent' ?
                                                <span className='flex pt-5 text-center justify-center'>{t("chargingSessionScreen.receiptRequested")}</span>
                                                :
                                                null
                                            }

                                                <>
                                                    <input type="text" className='border border-gray-300 bg-gray-100 w-full rounded-md px-4 py-2 focus:outline-none focus:border-green-500 text-center text-black' placeholder={t("chargingSessionScreen.enterYourEmail")} onBlur={(e: any) => setUserEmail(e.target.value)} onChange={(e: any) => changeReceiptRequestEmailAddress(e.target.value)}/>
                                                    {
                                                        invoiceEmailState == 'sent' ? 
                                                        <button className={'flex bg-green-500 w-full text-center justify-center py-3 mt-5 rounded-md text-black text-md'} onClick={requestEmailInvoice}>{t("chargingSessionScreen.emailRequested")}</button>
                                                        :
                                                        <button className={'flex bg-iparkOrange800 w-full text-center justify-center py-3 mt-5 rounded-md text-black text-md'} onClick={requestEmailInvoice}>{t("chargingSessionScreen.requestEmailReceipt")}</button>
                                                    }
                                                </></>
                                    </div>
                                :
                                <></>
                            }
                            
                            </>
                    }            
                </div>
            </div>
        )
    }
